import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {Editor} from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
import ShowSnack from "../../../util/ShowSnack";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {IconButton} from "@mui/material";
import Grid from "@mui/material/Grid";
import ImageUpload from "../../../util/ImageUpload";
import ImageUploadCustom from "../../../util/ImageUploadCustom";

export default function QuestionEditor()
{
    const editorRef = useRef(null);
    const { id } = useParams();
    const [testData, setTestData] = useState([]);
    const [testContent, setTestContent] = useState('');
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');


    useEffect(() => {
        getTest();
    }, [id]);

    function getTest() {
        axios
            .get(`/api/get/questionAndAnswers/${id}`)
            .then(function (response) {
                console.log(response.data);
                setTestData(response.data);
                setTestContent(response.data.content);
                setInputs(values => ({ ...values, title: response.data.title }));
                setInputs(values => ({ ...values, content: response.data.content }));
                const initialInputValues = {};
                response.data.answers.forEach((answer) => {
                    initialInputValues[`answer_${answer.id}`] = answer.answer;
                    initialInputValues[`answer_point_${answer.id}`] = answer.point;
                    initialInputValues[`answer_type_${answer.id}`] = answer.type;
                });
                setInputs(values => ({ ...values, ...initialInputValues }));
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('question_id', id);
        axios.post('/api/edit/questionAndAnswers', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres mentés!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    const deleteQuestion = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('question_id', id);
        axios.post('/api/delete/question', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres törlés!');
                    setSeverity('info');
                    window.location.href = `/edit/test/${testData.test_id}`;
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    }

    const deleteAnswer = async(answerId) => {
        const formData = new FormData();
        formData.append('answer_id', answerId);
        axios.post('/api/delete/answer', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres törlés!');
                    setSeverity('info');
                    getTest();
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    }

    const createNewAnswer = async(type) => {
        const formData = new FormData();
        formData.append('question_id', id);
        formData.append('type', type);
        axios.post('/api/create/answer', formData)
            .then(function (response) {
                if (response.data  !== 0) {
                    getTest();
                }
            });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    useEffect(() => {
        getTest();
    }, [id]);

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Typography variant={"h6"} sx={{ mb: 2 }}>Kérdés</Typography>
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={testContent}
                    onEditorChange={(newValue, editor) => {
                        setInputs(values => ({ ...values, content: newValue }));
                    }}
                    init={{
                        height: 500,
                        menubar: 'file edit view insert format tools table help',
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
                            'importcss', 'autosave', 'directionality', 'codesample', 'emoticons',
                            'accordion'
                        ],
                        toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | insertfile image | link image | table media | lineheight outdent indent | forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                        image_advtab: true,
                        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                        noneditable_class: 'mceNonEditable',
                        toolbar_mode: 'sliding',
                        contextmenu: 'link image table',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
                        automatic_uploads: true,
                        images_upload_url: '/api/create/postImage',
                        relative_urls: false,            // Disable relative URLs
                        remove_script_host: false,       // Keep the domain part of the URL
                        convert_urls: true,
                    }}
                />
                <Button
                    onClick={() => createNewAnswer('text')}
                    fullWidth
                    color={"success"}
                    variant="contained"
                    sx={{ mt: 1}}
                >
                    Új szöveges válasz hozzáadása
                </Button>
                <Button
                    onClick={() => createNewAnswer('image')}
                    fullWidth
                    color={"success"}
                    variant="contained"
                    sx={{ mt: 1 }}
                >
                    Új képes válasz hozzáadása
                </Button>
                <Button
                    onClick={() => createNewAnswer('textfield')}
                    fullWidth
                    color={"success"}
                    variant="contained"
                    sx={{ mt: 1 }}
                >
                    Szövegmező válasz hozzáadás
                </Button>
                <Button
                    onClick={() => createNewAnswer('url')}
                    fullWidth
                    color={"success"}
                    variant="contained"
                    sx={{ mt: 1 }}
                >
                    URL válasz hozzáadás
                </Button>
                <Button
                    onClick={() => createNewAnswer('upload')}
                    fullWidth
                    color={"success"}
                    variant="contained"
                    sx={{ mt: 1 }}
                >
                    File feltöltés válasz hozzáadás
                </Button>
                <Button
                    onClick={() => createNewAnswer('voice')}
                    fullWidth
                    color={"success"}
                    variant="contained"
                    disabled
                    sx={{ mt: 1 }}
                >
                    Hang alapú válasz hozzáadás
                </Button>
                <Typography variant={"h6"} sx={{ mt: 3, mb: 2 }}>Válaszok</Typography>
                <Box>
                    {testData.answers && testData.answers.map((answer, index) => (
                        <Grid container spacing={2} sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} key={index}>
                            <Grid item xs={10}>
                                {answer.type === 'text' &&
                                    <TextField
                                        key={index}
                                        label={`Válasz`}
                                        name={`answer_${answer.id}`}
                                        value={inputs[`answer_${answer.id}`] || ''}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                    />
                                }
                                {answer.type === 'image' &&
                                    <>
                                        <img src={`/images/questions/${id}/${answer.id}.webp`} width={"100%"}/>
                                        <ImageUploadCustom questionId={id} answerId={answer.id}/>
                                    </>
                                }
                                {answer.type === 'textfield' &&
                                    <h3>Szövegmező</h3>
                                }
                                {answer.type === 'upload' &&
                                    <h3>Feltöltés</h3>
                                }
                                {answer.type === 'url' &&
                                    <h3>URL</h3>
                                }
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    key={index}
                                    label={`Pont`}
                                    name={`answer_point_${answer.id}`}
                                    value={inputs[`answer_point_${answer.id}`] || 0}
                                    type="number"
                                    onChange={handleChange}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => deleteAnswer(answer.id)} sx={{ mt: 1 }}><DeleteForeverIcon color="error"/></IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="success"
                        sx={{ mt: 5, mb: 3 }}
                    >
                        Mentés
                    </Button>
                    <Button
                        onClick={deleteQuestion}
                        fullWidth
                        variant="outlined"
                        color="error"
                        sx={{ mt: 10, mb: 3 }}
                    >
                        Kérdés törlése
                    </Button>
                </Box>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
