import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {Editor} from "@tinymce/tinymce-react";
import Button from "@mui/material/Button";
import ShowSnack from "../../../util/ShowSnack";
import TestQuestionList from "./TestQuestionList";
import CustomSlider from "../../../util/CustomSlider";
import LessonMenuBar from "../../courses/lessons/LessonMenuBar";
import TestMenuBar from "../TestMenuBar";
import Grid from "@mui/material/Grid";
import NotesIcon from "@mui/icons-material/Notes";
import {useSessionData} from "../../../auth/SessionDataProvider";
import SaveIcon from "@mui/icons-material/Save";

export default function TestEditor({ colorPalette })
{
    const editorRef = useRef(null);
    const { id } = useParams();
    const [testData, setTestData] = useState([]);
    const [testContent, setTestContent] = useState('');
    const [inputs, setInputs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [questionCount, setQuestionCount] = useState(5);
    const [successPercent, setSuccessPercent] = useState(60);
    const { hexToShadow } = useSessionData();
    const navigate = useNavigate();

    useEffect(() => {
        getTest();
    }, [id]);

    function getTest() {
        axios
            .get(`/api/get/test/${id}`)
            .then(function (response) {
                setTestData(response.data);
                setTestContent(response.data.content);
                setQuestionCount(response.data.question_num);
                setSuccessPercent(response.data.success_percent)
                setInputs(values => ({ ...values, title: response.data.title }));
                setInputs(values => ({ ...values, content: response.data.content }));
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        formData.append('test_id', id);
        formData.append('question_count', questionCount);
        formData.append('success_percent', successPercent);
        axios.post('/api/edit/test', formData)
            .then(function (response) {
                if (response.data  === 1) {
                    setSnackOpen(true);
                    setMessage('Sikeres mentés!');
                    setSeverity('success');
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    const createNewQuestion = async(type) => {
        const formData = new FormData();
        formData.append('test_id', id);
        formData.append('type', type);
        axios.post('/api/create/question', formData)
            .then(function (response) {
                if (response.data  !== 0) {
                    const newQuestionId = response.data;
                    navigate(`/edit/question/${newQuestionId}`);
                }
            });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    useEffect(() => {
        getTest();
    }, [id]);

    return (
        <>
            <TestMenuBar parent={testData} colorPalette={colorPalette}/>
            <Box
                className={`sidebar-profile-container`}
                bgcolor={colorPalette.paper}
                sx={{ boxShadow: hexToShadow(colorPalette.paper), mb: 2 }}
            >
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid className="chat-bubble-meta" bgcolor={colorPalette.dark} >
                        <Typography variant={"h6"} color={colorPalette.text_on_dark}>Leírás</Typography>
                    </Grid>
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={testContent}
                        onEditorChange={(newValue, editor) => {
                            setInputs(values => ({ ...values, content: newValue }));
                        }}
                        init={{
                            height: 250,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
                        <Typography variant={"h6"} sx={{ mb: 1, mt: 2 }}>Kérdések száma:</Typography>
                        <CustomSlider value={questionCount} setValue={setQuestionCount}  />
                        <Typography variant={"h6"} sx={{ mb: 1, mt: 1 }}>Elvárt százalék:</Typography>
                        <CustomSlider value={successPercent} setValue={setSuccessPercent} overrideMaxValue={100} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="success"
                            sx={{ mt: 3, mb: 5 }}
                            endIcon={<SaveIcon/>}
                        >
                            Mentés
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box
                className={`sidebar-profile-container`}
                bgcolor={colorPalette.paper}
                sx={{ boxShadow: hexToShadow(colorPalette.paper), mb: 2, mt: 3 }}
            >
                <Grid className="chat-bubble-meta" bgcolor={colorPalette.dark} >
                    <Typography variant={"h6"} color={colorPalette.text_on_dark}>Kérdések</Typography>
                </Grid>
                <Box sx={{ mt: 2, mb: 2, ml: 2, mr: 2 }}>
                    <Button
                        onClick={() => createNewQuestion('text')}
                        fullWidth
                        color={"success"}
                        variant="contained"
                        sx={{ mt: 1, mb: 3 }}
                    >
                        Új szöveges kérdés hozzáadása
                    </Button>
                    <Button
                        onClick={() => createNewQuestion('voice')}
                        fullWidth
                        color={"success"}
                        variant="contained"
                        sx={{ mt: 1, mb: 3 }}
                    >
                        Új hangalapú kérdés hozzáadása
                    </Button>
                    <Box>
                        <TestQuestionList testId={id} colorPalette={colorPalette} />
                    </Box>
                    <Button
                        component={Link}
                        to={`/test/${id}`}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Tesztelés
                    </Button>
                </Box>
            </Box>
            <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
        </>
    );
}
