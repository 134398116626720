import {Link, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import './App.css';
import CreateUser from './components/admin/create/CreateUser';
import ListUser from './components/admin/ListUser';
import Login from "./components/auth/Login";
import Header from "./components/Header";
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RequireAdmin from "./components/auth/RequireAdmin";
import RequireAuth from "./components/auth/RequireAuth";
import {darkTheme, theme} from './theme';
import RequireSub from "./components/auth/RequireSub";
import React, {useEffect, useState} from 'react';
import axios from "axios";
import HeaderLoggedIn from "./components/HeaderLoggedIn";
import UserFeed from "./components/modules/feed/UserFeed";
import UserFeedLatest from "./components/modules/feed/UserFeedLatest";
import CourseList from "./components/modules/courses/CourseList";
import SubjectList from "./components/modules/courses/SubjectList";
import TopicList from "./components/modules/courses/TopicList";
import LessonList from "./components/modules/courses/LessonList";
import ChapterList from "./components/modules/courses/ChapterList";
import ViewLesson from "./components/modules/courses/lessons/ViewLesson";
import LessonEditor from "./components/modules/courses/lessons/LessonEditor";
import TestEditor from "./components/modules/test/editor/TestEditor";
import QuestionEditor from "./components/modules/test/editor/QuestionEditor";
import StartTest from "./components/modules/test/StartTest";
import ViewTest from "./components/modules/test/ViewTest";
import UserAllMessages from "./components/modules/messages/UserAllMessages";
import MessageViewWithHistory from "./components/modules/messages/MessageViewWithHistory";
import ViewMessages from "./components/modules/messages/ViewMessages";
import {Box} from "@mui/system";
import UserCardSmall from "./components/user/UserCardSmall";
import ViewGroups from "./components/modules/groups/ViewGroups";
import ViewGroup from "./components/modules/groups/ViewGroup";
import EditGroup from "./components/modules/groups/EditGroup";
import CourseStructureView from "./components/modules/courses/CourseStructureView";
import MessagesMenuBar from "./components/modules/messages/menubar/MessagesMenuBar";
import EditOrder from "./components/modules/groups/order/EditOrder";
import {useSessionData} from "./components/auth/SessionDataProvider";
import ViewGroupMembers from "./components/modules/groups/members/ViewGroupMembers";
import CourseManager from "./components/modules/courses/CourseManager";
import UserNotes from "./components/modules/sidebar/UserNotes";
import AiChat from "./components/modules/sidebar/AiChat";
import UserCardSmallById from "./components/user/UserCardSmallById";
import StringAvatar from "./components/user/StringAvatar";
import {Accordion, AccordionDetails, AccordionSummary, AvatarGroup, IconButton} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import Button from "@mui/material/Button";
import TaskManager from "./components/modules/groups/taskmanager/TaskManager";
import HomeScreen from "./components/modules/home/HomeScreen";
import UserAvatar from "./components/user/UserAvatar";
import Calendar from "./components/util/Calendar";
import Grid from "@mui/material/Grid";

function App()
{
    const [adminMode, setAdminMode] = useState(localStorage.getItem('adminMode') === 'true');
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const [shadows, setShadows] = useState(localStorage.getItem('shadows') === 'true');
    const [settings, setSettings] = useState([]);
    const { sessionData, setSessionData, isOnPath } = useSessionData();
    const [textToSpeech, setTextToSpeech] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const [colorPalette, setColorPalette] = useState({
        background: '#e7f5ff',
        secondary: '#b3dbb3',
        paper: '#dfe0d2',
        text: '#000000',
        text_on_dark: '#ffffff',
        text_on_secondary: '#ffffff',
        dark: '#222222FF',
        appbar: '#8fd2d1',
        sidebar: '#8fd2d1',
        sanguine: '#790000',
        shadow: '#000000',
    });
    const [modifiedLightTheme, setModifiedLightTheme] = useState(theme);
    const [modifiedDarkTheme, setModifiedDarkTheme] = useState(darkTheme);
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded);
    };

    function hexToShadow(hex, opacity = 0.7, size = 25) {
        const darkShadowOp = 0.2;
        const usedOpacity = darkMode ? darkShadowOp : opacity;
        if (!shadows) {
            return 'none';
        }

        if (hex) {
            hex = hex.replace('#', '');
            const bigint = parseInt(hex, 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;

            return `5px 5px ${size}px rgba(${r}, ${g}, ${b}, ${usedOpacity})`;
        }
    }

    function toggleShadows() {
        setShadows((prevMode) => !prevMode);
        localStorage.setItem('shadows', (!shadows).toString());
    }

    function calculateLuminance(hexColor) {
        const removeHash = (hexColor) => hexColor.replace(/^#/, '');
        const parseHex = (hexColor) => parseInt(removeHash(hexColor), 16) / 255;

        const color = {
            r: parseHex(hexColor.slice(1, 3)),
            g: parseHex(hexColor.slice(3, 5)),
            b: parseHex(hexColor.slice(5, 7)),
        };

        return 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b;
    }


    function getContrastRatio(foregroundColor, backgroundColor) {
        const L1 = calculateLuminance(foregroundColor);
        const L2 = calculateLuminance(backgroundColor);

        const brighter = Math.max(L1, L2);
        const darker = Math.min(L1, L2);

        return (brighter + 0.05) / (darker + 0.05);
    }

    function chooseTextColor(backgroundColor) {
        const contrastThreshold = 4;
        const textColor = calculateLuminance(backgroundColor) < 0.3 ? '#ffffff' : '#000000';

        return getContrastRatio(textColor, backgroundColor) >= contrastThreshold ? textColor : (textColor === '#ffffff' ? '#000000' : '#ffffff');
    }

    function calculateTextColors() {
        colorPalette.text = chooseTextColor(colorPalette.paper);
        colorPalette.text_on_dark = chooseTextColor(colorPalette.dark);
        colorPalette.text_on_secondary = chooseTextColor(colorPalette.secondary);
        setColorPalette(colorPalette);
    }

    function refreshTheme() {
        calculateTextColors();
        const modifiedPalette = {
            ...theme.palette,
            oksi: {
                light: colorPalette.oksiLight,
                main: colorPalette.oksiMain,
                dark: colorPalette.oksiDark,
                sanguine: colorPalette.oksiSanguine,
            },
            typography: {
                ...theme.typography,
                body1: {
                    color: colorPalette.text,
                    textAlign: 'justify',
                    marginBottom: 12,
                    marginLeft: 16,
                    marginRight: 16,
                    fontSize: 18,
                },
            },
        };

        theme.palette.oksi = modifiedPalette.oksi;

        const modifiedDarkPalette = {
            ...darkTheme.palette,
            oksi: {
                light: colorPalette.oksiLight,
                main: colorPalette.oksiMain,
                dark: colorPalette.oksiDark,
                sanguine: colorPalette.oksiSanguine,
            },
            typography: {
                ...darkTheme.typography,
                body1: {
                    textColor: colorPalette.text,
                    textAlign: 'justify',
                    marginBottom: 12,
                    marginLeft: 16,
                    marginRight: 16,
                    fontSize: 18,
                },
            }
        };

        darkTheme.palette.oksi = modifiedDarkPalette.oksi;

        setModifiedLightTheme(theme);
        setModifiedDarkTheme(darkTheme);
    }

    useEffect(() => {
        refreshTheme();
    }, [colorPalette]);

    useEffect(() => {
        getSessionData();
    }, []);

    function getSessionData() {
        axios.get('/api/user/session')
            .then((result) => {
                console.log(result);
                if (result.data !== false) {
                    setSessionData({
                        ...sessionData,
                        userId: result.data.user_id,
                        rights: result.data.rights,
                        logged: result.data.logged,
                        username: result.data.username,
                        email: result.data.email,
                        friends: Object.values(result.data.friends),
                        session_users: result.data.session_users,
                        notes: result.data.user_profile.notes,
                        reg_complete: result.data.user_profile.reg_complete,
                    });
                    if (result.data.settings && result.data.settings.Buttons) {
                        setSettings(result.data.settings);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const toggleSidebarVisible = () => {
        setIsSidebarVisible((prevMode) => !prevMode);
    };

    const toggleAdminMode = () => {
        setAdminMode((prevMode) => !prevMode);
        localStorage.setItem('adminMode', adminMode.toString());
    };

    const toggleDarkMode = () => {
        setDarkMode((prevMode) => !prevMode);
        localStorage.setItem('darkMode', (!darkMode).toString());
    };

    return (
      <ThemeProvider theme={darkMode ? modifiedDarkTheme : modifiedLightTheme}>
      <CssBaseline />
          {(sessionData.reg_complete === 0 && !location.pathname.startsWith('/register'))
              && navigate('/register')
          }
      <Grid container direction="column" width={"80vw"} sx={{ marginLeft: drawerOpen ? 45 : 0, transition: 'margin 0.2s', alignSelf: 'center' }}>
          <Grid item>
                {sessionData.isLogged ? (
                    <HeaderLoggedIn
                        adminMode={adminMode}
                        toggleAdminMode={toggleAdminMode}
                        settings={settings}
                        setSettings={setSettings}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        textToSpeech={textToSpeech}
                        darkMode={darkMode}
                        toggleDarkMode={toggleDarkMode}
                        isSidebarVisible={isSidebarVisible}
                        toggleSidebarVisible={toggleSidebarVisible}
                        colorPalette={colorPalette}
                        setColorPalette={setColorPalette}
                        hexToShadow={hexToShadow}
                        shadows={shadows}
                        toggleShadows={toggleShadows}
                    />) : (
                    <Header colorPalette={colorPalette}/>
                )}
          </Grid>
          <Grid item container >
            <Grid item xs={3}>
                {sessionData.isLogged && (
                    <Box className="sidebar animated-resize" hidden={!isSidebarVisible}>
                        {sessionData.selected_users.length > 0 && (
                            <Box sx={{ mb: 2 }} >
                                <Accordion expanded={isAccordionExpanded} onChange={toggleAccordion} >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant={"h6"} color={colorPalette.text_on_dark}><BeenhereIcon/> Kiválasztva ({sessionData.selected_users.length})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {sessionData.selected_users.map((friend, index) => (
                                            <UserCardSmallById key={index} userId={friend} colorPalette={colorPalette} hexToShadow={hexToShadow} />
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                                {!isAccordionExpanded && (
                                    <Box bgcolor={colorPalette.dark} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <AvatarGroup max={7} sx={{ mt: 1, mb: 1}}>
                                            {sessionData.selected_users.map((friend, index) => (
                                                <UserAvatar user={sessionData.session_users[friend]} key={index} />
                                            ))}
                                        </AvatarGroup>
                                    </Box>
                                )}
                            </Box>
                        )}
                        <UserNotes colorPalette={colorPalette} />
                        {/*<AiChat colorPalette={colorPalette} setTextToSpeech={setTextToSpeech} />*/}
                        <Calendar colorPalette={colorPalette} />
                        {location.pathname.startsWith('/user') && (
                            <>
                                <Box sx={{ mt: 0, mb: 0, boxShadow: hexToShadow(colorPalette.shadow) }}>
                                    <iframe src="https://discord.com/widget?id=1162790760798765158&theme=dark" width="100%" height="300" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                                </Box>
                                <Box sx={{ mt: 0, mb: 0, boxShadow: hexToShadow(colorPalette.shadow) }}>
                                    {sessionData.friends.map((friend, index) => (
                                        <UserCardSmall key={index} userCard={sessionData.session_users[friend.id]} colorPalette={colorPalette} hexToShadow={hexToShadow}/>
                                    ))}
                                </Box>
                            </>
                        )}
                    </Box>
                )}
                </Grid>

                <Grid item xs={isSidebarVisible ? 9 : 12} className="main-content">
                  <Routes>
                    <Route path="register" element={<CreateUser colorPalette={colorPalette} setIsSidebarVisible={setIsSidebarVisible} getSessionData={getSessionData} />} />
                    <Route path="login" element={<Login />} />
                  </Routes>

                  <RequireAuth>
                    <Routes>
                        <Route path="home" element={<HomeScreen colorPalette={colorPalette} isSidebarVisible={isSidebarVisible} setTextToSpeech={setTextToSpeech} />} />
                        <Route path="feed" element={<UserFeedLatest isSidebarVisible={isSidebarVisible} colorPalette={colorPalette} hexToShadow={hexToShadow}/>} />
                        <Route path="feed/all" element={<UserFeed />} />
                        <Route path="view/lesson/:id" element={<ViewLesson sessionData={sessionData} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow}/>} />
                        <Route path="view/test/:id" element={<ViewTest colorPalette={colorPalette} />} />
                        <Route path="test/:id" element={<StartTest colorPalette={colorPalette}/>} />
                        <Route path="user/message/fresh" element={<ViewMessages setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} friends={sessionData.friends}/>} />
                        <Route path="user/message/unread" element={<ViewMessages setTextToSpeech={setTextToSpeech} inboxType={"messagesUnread"} colorPalette={colorPalette} hexToShadow={hexToShadow} friends={sessionData.friends}/>} />
                        <Route path="user/message/outgoing" element={<ViewMessages setTextToSpeech={setTextToSpeech} inboxType={"messagesOutgoing"} colorPalette={colorPalette} hexToShadow={hexToShadow} friends={sessionData.friends}/>} />
                        <Route path="user/message/incoming" element={<ViewMessages setTextToSpeech={setTextToSpeech} inboxType={"messagesIncoming"} colorPalette={colorPalette} hexToShadow={hexToShadow} friends={sessionData.friends}/>} />
                        <Route path="user/message/all" element={<><MessagesMenuBar colorPalette={colorPalette} hexToShadow={hexToShadow}/><UserAllMessages /></>} />
                        <Route path="view/message/:id" element={<MessageViewWithHistory setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} />} />
                        <Route path="view/groups/all" element={<ViewGroups siteType={"all"} setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} isSidebarVisible={isSidebarVisible} />} />
                        <Route path="view/group/:id" element={<ViewGroup setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} isSidebarVisible={isSidebarVisible} sessionData={sessionData}/>}/>
                        <Route path="view/group/members/:id" element={<ViewGroupMembers setTextToSpeech={setTextToSpeech} colorPalette={colorPalette} hexToShadow={hexToShadow} isSidebarVisible={isSidebarVisible} sessionData={sessionData}/>}/>
                        <Route path="edit/group/:id" element={<EditGroup colorPalette={colorPalette} hexToShadow={hexToShadow}/>} />
                        <Route path="edit/group/order/:id" element={<EditOrder colorPalette={colorPalette} hexToShadow={hexToShadow} isSidebarVisible={isSidebarVisible} sessionData={sessionData}/>} />
                        <Route path="view/manager/:id/*" element={<TaskManager colorPalette={colorPalette}/>} />
                    </Routes>
                    <RequireSub>
                      <Routes>
                      </Routes>
                    </RequireSub>
                  </RequireAuth>

                  <RequireAdmin>
                    <Routes>
                      <Route path="edit/lesson/:id" element={<LessonEditor colorPalette={colorPalette} isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />} />
                      <Route path="edit/test/:id" element={<TestEditor colorPalette={colorPalette} />} />
                      <Route path="edit/question/:id" element={<QuestionEditor />} />
                      <Route path="course/manager/*" element={<CourseManager colorPalette={colorPalette} />} />
                      <Route path="course/manage/:parentId" element={<CourseStructureView colorPalette={colorPalette} hexToShadow={hexToShadow} />} />
                      <Route path="admin/list/users" element={<ListUser />} />
                      <Route path="admin/list/courses" element={<CourseList />} />
                      <Route path="admin/list/subjects/:id" element={<SubjectList />} />
                      <Route path="admin/list/topics/:id" element={<TopicList />} />
                      <Route path="admin/list/chapters/:id" element={<ChapterList />} />
                      <Route path="admin/list/lessons/:id" element={<LessonList />} />
                    </Routes>
                  </RequireAdmin>
                </Grid>
      </Grid>
          </Grid>
      </ThemeProvider>
    );
}
export default App;
