import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import axios from "axios";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import ShowSnack from "../../../util/ShowSnack";

export default function EditFolder({ parentId, title, getCourses })
{
    const [inputs, setInputs] = useState({
        title: '',
    });
    const [snackOpen, setSnackOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const handleSubmit = async(e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const [key, value] of Object.entries(inputs)) {
            formData.append(key, value);
        }
        axios.post('/api/edit/folder', formData)
            .then(function (response) {
                if (response.data === 1) {
                    setSnackOpen(true);
                    setMessage('Mappa módosítva!');
                    setSeverity('success');
                    setInputs([]);
                    getCourses();
                } else {
                    setSnackOpen(true);
                    setMessage('Sikertelen művelet!');
                    setSeverity('error');
                }
            });
    };

    useEffect(() => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            folder_id: parentId,
            title: title,
        }));
    }, [parentId, title]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    return (
    <>
        <Divider />
        <Container>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    id="title"
                    label="Mappa neve"
                    name="title"
                    value={inputs.title}
                    onChange={handleChange}
                />
                <Button
                    color={"success"}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                >
                    Szerkesztés
                </Button>
            </Box>
        </Container>
        <ShowSnack snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={message} severity={severity} />
    </>
    );
}
