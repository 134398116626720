import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomFileInput from "./CustomFileInput";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function ImageUploadCustom({questionId, answerId, aspect = 1, finalWidth = null }) {
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [open, setOpen] = useState(false);
    const imageRef = useRef(null);
    const MAX_WIDTH = 750;

    const resizeImage = (file) => {
        return new Promise((resolve) => {
            const img = new Image();
            const reader = new FileReader();
            reader.onload = (e) => {
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const scaleFactor = Math.min(1, MAX_WIDTH / img.width);

                canvas.width = img.width * scaleFactor;
                canvas.height = img.height * scaleFactor;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 1);
            };
        });
    };

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const resizedBlob = await resizeImage(file);
            const resizedUrl = URL.createObjectURL(resizedBlob);
            setImageSrc(resizedUrl);
            setOpen(true);
        }
    };

    useEffect(() => {
        if (imageSrc) {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                imageRef.current = image;
                const imageWidth = image.width;
                const imageHeight = image.height;

                let initialCrop;
                if (imageWidth / imageHeight > aspect) {
                    // Image is wider than aspect ratio, set crop to full height
                    initialCrop = {
                        unit: '%',
                        width: (100 * aspect) * (imageHeight / imageWidth),
                        height: 100,
                        x: (100 - ((100 * aspect) * (imageHeight / imageWidth))) / 2,
                        y: 0,
                        aspect,
                    };
                } else {
                    // Image is taller than aspect ratio, set crop to full width
                    initialCrop = {
                        unit: '%',
                        width: 100,
                        height: (100 / aspect) * (imageWidth / imageHeight),
                        x: 0,
                        y: (100 - ((100 / aspect) * (imageWidth / imageHeight))) / 2,
                        aspect,
                    };
                }

                setCrop(initialCrop);
            };
            image.onerror = () => {
                console.error('Error loading image:', imageSrc);
            };
        }
    }, [imageSrc, aspect]);

    const getCroppedImageBlob = (image, crop) => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            const resizedWidth = finalWidth || (crop.width * scaleX);
            const resizedHeight = resizedWidth / aspect;

            canvas.width = resizedWidth;
            canvas.height = resizedHeight;

            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                canvas.width,
                canvas.height
            );

            canvas.toBlob((blob) => {
                if (blob) {
                    const croppedFile = new File([blob], "cropped_image.webp", { type: "image/webp" });
                    resolve(croppedFile);
                } else {
                    console.error("Canvas toBlob failed");
                }
            }, 'image/webp', 0.9);
        });
    };

    const handleCropComplete = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            try {
                const croppedBlob = await getCroppedImageBlob(imageRef.current, crop);
                setCroppedImage(croppedBlob);
            } catch (error) {
                console.error('Error cropping image:', error);
            }
        } else {
            console.error('Crop area not properly set or image reference missing.');
        }
    };

    const handleImageUpload = () => {
        if (croppedImage) {
            const formData = new FormData();
            formData.append('question_id', questionId);
            formData.append('answer_id', answerId);
            formData.append('image', croppedImage);
            axios.post('/api/upload/answerImage', formData)
                .then(function (response) {
                    console.log(response);
                    if (response.data  !== 0) {
                        setOpen(false);
                    }
                });
        }
    };

    return (
        <>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                Upload Image
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Image Upload</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Grid item xs={12}>
                            <CustomFileInput handleImageChange={handleImageChange} customText={"Tölts fel vagy húzz ide képet"} />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {imageSrc && (
                                <ReactCrop
                                    src={imageSrc}
                                    crop={crop}
                                    onChange={(newCrop) => setCrop(newCrop)}
                                    onComplete={(newCompletedCrop) => {
                                        handleCropComplete(newCompletedCrop);
                                    }}
                                    aspect={aspect}
                                >
                                    <img src={imageSrc} alt="Crop" ref={imageRef} style={{ maxWidth: '100%' }} />
                                </ReactCrop>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleImageUpload} color="primary" disabled={!croppedImage}>
                        Mentés
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ImageUploadCustom;
